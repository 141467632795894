<template>
  <div class="container">
    <div style="min-width: 1100px;">
      <el-row>
        <el-col :span="9">
          <el-form class="left-buy">
            <el-form-item  label="证券代码">
              <div style="display: inline-block;width:100%" >
                <CodeSearch @codeSelectChane="codeSelectChane" codeType="1" :multiple="false" :codeList="codeList"></CodeSearch>
              </div>
              
            </el-form-item>
            <el-form-item  label="证券名称">
              <span style="font-size: 14px;margin-left: 3px;color:black;">{{codeName}}</span>
            </el-form-item>
            <div style="margin-top: 3px;">
              <BuyForm :code="code" :price="price" />
            </div>
          </el-form>
        </el-col>
        <el-col :span="15">
          <div class="charts_box">
            <div style="margin-left: 20px;"><i class="card-line"></i>日K</div>
            <div class="left">
              <div id="echartsDom" :ref="myCharts"></div>
            </div>
            <div class="right">
              <trade-card :code="code" @clickPrice="clickPrice"></trade-card>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    
    
    
    
    
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onBeforeUnmount } from "vue"
import { buyData } from "../data"
import * as echarts from 'echarts';
import TradeCard from '@/components/TradeDealCard'
import BuyForm from "../BuyForm"
import CodeSearch from "@/components/CodeSearch"
import { dealCodeSearch } from "../../../course-manage/trade-manage/tradeMethods";
import { findQuotationByCondition } from "../../../course-manage/trade-manage/tradeApi";
import { codeDealName } from "../commonMethods.js";

var myChart;
export default {
  name: 'buy',
  components: {
    TradeCard,
    BuyForm,
    CodeSearch
  },
  // props:{
  //   codeList:{}
  // },
  setup() {
    let refs = '';
    const myCharts = el => {
      refs = el;
    }
    let data = reactive({
      price: '',
      code: 'sh600000',
      codeList: 'sh600000',
      codeName: '浦发银行',
      option: {
        xAxis: {
          data: ['2017-10-24', '2017-10-25', '2017-10-26', '2017-10-27', '2017-10-28', '2017-10-29', '2017-10-30', '2017-10-31']
        },
        yAxis: {},
        series: [{
          type: 'k',
          data: [
            [20, 34, 10, 38],
            [40, 35, 30, 50],
            [31, 38, 33, 44],
            [38, 15, 5, 42],
            [38, 15, 5, 42],
            [38, 15, 5, 42],
            [38, 15, 5, 10],
          ]
        }]
      }
    }
    )
    onMounted(() => {
      getChart()
      window.addEventListener("resize", echartsresize);
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', echartsresize)
    })

    const echartsresize = () => {
      myChart.resize();
    }

    let clickPrice = (value) => {
      data.price = value
    }

    // 获取chart
    let getChart = () => {
      myChart = echarts.init(refs)
      let exchangeType = data.code.slice(0, 2) === "sh" ? "1" : "0";
      let parame = {
        startTime: (Date.parse(new Date()) / 1000 - 365 * 3 * 24 * 3600),
        endTime: Date.parse(new Date()) / 1000,
        code: data.code.slice(2),
        exchangeType: exchangeType
      }
      findQuotationByCondition(parame).then(res => {
        let arr = []
        if (res.data.code === '200') {
          let newRes = res.data.data
          for (let i in newRes.marketInformations) {
            let dataMations = newRes.marketInformations[i]
            arr.push([dataMations.date, dataMations.fopen, dataMations.fclose, dataMations.flow, dataMations.fhigh])
          }
          let resOption = splitData(arr);
          function splitData(rawData) {
            var categoryData = [];
            var values = [];
            for (var i = 0; i < rawData.length; i++) {
              categoryData.push(rawData[i].splice(0, 1)[0])
              values.push(rawData[i])
            }
            return {
              categoryData: categoryData,
              values: values
            }
          }
          let xAxis = resOption.categoryData
          let value = resOption.values

          data.option = {
            // backgroundColor: '#12cf96',
            backgroundColor: '#fff',
            title: {
              left: 0
            },
            tooltip: {
              show: true,
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              },
              formatter: function (param) {
                // return param.name + '<br>' + (param.data.coord || '')
                param = param[0];
                return [
                  '日期' + "          " + param.name + '<hr size=1 style="margin: 3px 0">',
                  '今开' + "          " + param.data[1] + '<br/>',
                  '昨收' + "          " + param.data[2] + '<br/>',
                  '最低' + "          " + param.data[3] + '<br/>',
                  '最高' + "          " + param.data[4] + '<br/>'
                ].join('');
              }
            },
            legend: {
              data: [""],
            },
            // legend: {
            //   data: ['日K', 'MA5', 'MA10', 'MA20', 'MA30']
            // },
            grid: {
              left: '10%',
              right: '10%',
              bottom: '15%'
            },
            xAxis: {
              type: 'category',
              data: xAxis,
              scale: true,
              boundaryGap: false,
              axisLine: { onZero: false },
              splitLine: { show: false },
              splitNumber: 20,
              min: 'dataMin',
              max: 'dataMax'
            },
            yAxis: {
              scale: true,
              splitArea: {
                show: true
              }
            },
            dataZoom: [
              {
                type: 'inside',
                start: 30,
                end: 70
              },
              {
                show: true,
                type: 'slider',
                y: '90%',
                start: 0,
                end: 75
              }
            ],
            series: [
              {
                name: '日K',
                type: 'k',
                data: value,
                itemStyle: {
                  normal: {
                    color: '#ec0000',
                    color0: '#00da3c',
                    borderColor: '#8A0000',
                    borderColor0: '#008F28'
                  }
                },
                markPoint: {
                  label: {
                    normal: {
                      formatter: function (param) {
                        return param != null ? Math.round(param.value) : ''
                      }
                    }
                  },
                  data: [
                    // {
                    //   name: 'highest value',
                    //   type: 'max',
                    //   valueDim: 'highest'
                    // },
                    // {
                    //   name: 'lowest value',
                    //   type: 'min',
                    //   valueDim: 'lowest'
                    // },
                    // {
                    //   name: 'average value on close',
                    //   type: 'average',
                    //   valueDim: 'close'
                    // }
                  ],
                },
                markLine: {
                  symbol: ['none', 'none'],
                  data: [
                    [
                      {
                        name: 'from lowest to highest',
                        type: 'min',
                        valueDim: 'lowest',
                        symbol: 'circle',
                        symbolSize: 10,
                        label: {
                          normal: { show: false },
                          emphasis: { show: false }
                        }
                      },
                      {
                        type: 'max',
                        valueDim: 'highest',
                        symbol: 'circle',
                        symbolSize: 10,
                        label: {
                          normal: { show: false },
                          emphasis: { show: false }
                        }
                      }
                    ]
                  ]
                }
              }
            ],
          }
          myChart.setOption(data.option)
        }
      })
    }
    // 切换 搜索
    let codeSelectChane = (value) => {
      console.log("value",value);
      
      data.codeList = value
      data.code = dealCodeSearch(value, { routeId: '1' })
      data.codeName = codeDealName(value, '1')
      getChart()
    }



    return {
      ...toRefs(data),
      clickPrice,
      myCharts,
      codeSelectChane
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 65vh;
  overflow: auto;
  .left-buy{
    min-width: 280px;
    margin: 0 20px;
    min-height: 500px;
    padding: 20px 13%;
    background: #F4F4FA;
    color: #65676B;
    font-size: 14px
  };
  .selectbox {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .charts_box {
    margin-bottom: 20px;
    min-width: 700px;
    .left {
      width: 100%;
      #echartsDom {
        height: 300px;
        width: 100%;
        margin-bottom: 30px;
      }
    }
    .right {
      width: 100%;
    }
  }
  .bottom {
    padding-right: 30%;
  }
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #3B6EFB;
}
.el-form-item{
  margin-bottom: 23px !important;
}
</style>
